







































import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  data() {
    return {
      mssalesProcess: [],
      ssetProcess: []
    }
  },
  computed: {
    error(): Error {
      return null
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('Processes/loadMSSalesProcess'),
      this.$store.dispatch('Processes/loadSSETProcess')
    ])
    this.mssalesProcess = this.$store.getters['Processes/mssalesProcess']
    this.ssetProcess = this.$store.getters['Processes/ssetProcess']
    console.log(this.mssalesProcess)
    console.log(this.ssetProcess)
  }
})
